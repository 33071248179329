import { persist, forget } from '@hypefactors/shared/js/utils/index.js'
import { fallbackRolePermission, userFactory } from '@hypefactors/shared/js/factories/user'

import { brandFactory } from '@/factories/brand'
import { CrossAuth } from '@/services/CrossStorage'

const state = {
  token: '',
  user: userFactory(),
  activeBrandId: null,
  brands: [],
  permissions: {}
}

const getters = {
  isLoggedIn (state) {
    return !!state.token
  },

  currentUser (state) {
    return state.user
  },

  activeBrandId (state) {
    return state.activeBrandId
  },

  activeBrand (state) {
    const brand = state.brands.find(brand => brand.id === state.activeBrandId)

    return brand || brandFactory()
  },

  isStaffMember (state) {
    return state.user.is_staff_member
  },

  userHasBrandPermission: state => (permission, brandId = state.activeBrandId) => {
    const roleForBrand = state.permissions[brandId]

    if (!roleForBrand) {
      return false
    }

    return roleForBrand['permissions'].hasOwnProperty(permission)
  },

  userBrandPermission: (state, getters) => (permission, brandId = state.activeBrandId) => {
    if (!getters.userHasBrandPermission(permission, brandId)) {
      return fallbackRolePermission()
    }

    return state.permissions[brandId]['permissions'][permission]
  },

  authorize: (state, getters) => (permission, brandId) => {
    /* Allow staff members everything */
    if (getters.isStaffMember) return { can: true }

    /* allow sending a boolean for edge cases */
    if (typeof permission === 'boolean') {
      const result = {
        can: permission
      }

      if (permission === true) return result

      return {
        ...result,
        reason: {
          cause: 'bool',
          type: 'bool'
        }
      }
    }

    return getters.userHasBrandPermission(permission, brandId)
      ? getters.userBrandPermission(permission, brandId)
      : false
  },

  authorizeBool: (state, getters) => (permission, brandId) => {
    return getters.authorize(permission, brandId)['can']
  }
}

const actions = {
  setUser ({ commit }, user) {
    commit('STORE_USER', user)
  },

  setUserBrands ({ commit }, brands) {
    commit('STORE_BRANDS', brands)
  },

  async logout ({ commit }) {
    commit('LOGOUT')
    commit('SET_ACTIVE_BRAND_ID', null)
    commit('CLEAR_USER_BRAND_PERMISSIONS')

    forget('access_token')
    forget('refresh_token')
    forget('active_brand')
  },

  /**
   * Stores the auth tokens
   * @param commit
   * @param {object} tokens
   * @param {string} tokens.accessToken
   * @param {string} tokens.refreshToken
   */
  storeAuthToken ({ commit }, tokens) {
    commit('STORE_AUTH_TOKEN', tokens.accessToken)

    persist('access_token', tokens.accessToken)
    persist('refresh_token', tokens.refreshToken)
  },

  async setActiveBrand ({ commit }, id) {
    commit('SET_ACTIVE_BRAND_ID', id)

    // TODO: Ideally this shouldn't be part of the global filters, but instead
    // it should be global activeBrandId passed on the headers automatically.
    //
    // This filter should only be used when we really want to filter by brands...
    commit('SET_FILTER', {
      name: 'brands', value: [id]
    }, { root: true })
  },

  syncDownGlobalAuthToken ({ dispatch }) {
    return CrossAuth.getAuthTokens().then(tokens => {
      if (tokens) {
        return dispatch('storeAuthToken', tokens)
      }

      return dispatch('logout')
    })
  },

  /**
   * Sets the auth token based on key factors
   * @returns {Promise<*>}
   */
  setAuthTokenIntelligently ({ dispatch }, { query }) {
    // inside Cypress tests we rely on localStorage to have the token
    if (window.Cypress && !query['syncDownGlobalAuthToken'] && !query['token']) {
      return Promise.resolve()
    }

    // if we have the auth query param, use it instead of the CrossStorage one
    if (query['token']) {
      return dispatch('storeAuthToken', {
        accessToken: query['token'],
        refreshToken: null
      })
    }

    return dispatch('syncDownGlobalAuthToken')
  },

  async syncUpGlobalAuth ({ dispatch }, tokens) {
    await CrossAuth.setAuthTokens(tokens)

    return dispatch('storeAuthToken', tokens)
  },

  async logoutGlobally ({ dispatch }) {
    await CrossAuth.delAuthTokens().catch(() => null)

    return dispatch('logout')
  }
}

const mutations = {
  LOGOUT (state) {
    state.token = null
    state.brands = []
    state.user = userFactory()
  },

  STORE_AUTH_TOKEN (state, token) {
    state.token = token
  },

  STORE_USER (state, user) {
    state.user = user
  },

  STORE_BRANDS (state, brands) {
    state.brands = brands
  },

  SET_ACTIVE_BRAND_ID (state, id) {
    state.activeBrandId = id

    persist('active_brand', id)
  },

  STORE_USER_BRAND_PERMISSIONS (state, { permissions, brandId }) {
    state.permissions[brandId] = permissions
  },

  CLEAR_USER_BRAND_PERMISSIONS (state) {
    state.permissions = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
