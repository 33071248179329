/**
 * The filter Vuex module is used to globally set filters,
 * most often used for globally applied query filters,
 * like brand, sentiment anf more on Facts page.
 */

import Vue from 'vue'
import _pick from 'lodash/pick'

const state = {
  filters: {}
}

const getters = {
  getFilter: (state) => (property, fallback) => {
    return typeof state.filters[property] !== 'undefined'
      ? state.filters[property]
      : fallback
  },
  hasFilter: state => filter => typeof state[filter] !== 'undefined',
  pickFilters: state => (filtersToPick = []) => _pick(state.filters, filtersToPick)
}

const mutations = {
  SET_FILTER (state, { name, value }) {
    if (!state.filters[name]) {
      return Vue.set(state.filters, name, value)
    }
    state.filters[name] = value
  },
  CLEAR_FILTERS (state) {
    state.filters = {}
  }
}

const actions = {
  setFilter ({ commit }, value) {
    commit('SET_FILTER', value)
  },
  clearAllFilters ({ commit }) {
    commit('CLEAR_FILTERS')
  },
  /**
   * Sets the filters from the URL.
   * Allows passing an array of allowed Query Params.
   * @param {Object} [params]
   * @param {Array<string>} [params.allowedQueryParams] - A list of allowed query params
   * @param {Object} [params.query] - An object to set filters from
   */
  setFiltersFromQuery ({ state, commit, rootState }, params = {}) {
    const { allowedQueryParams = [], query = rootState.route.query } = params
    Object.entries(query)
      // Allows setting arrays from query params, without the need for `[]` in the name
      .map(([key, value]) => {
        if (key.includes('[]')) {
          key = key.replace('[]', '')
          value = Array.isArray(value) ? value : [value]
        }
        return [key, value]
      })
      // remove the ones that are not allowed
      .filter(([key]) => allowedQueryParams.includes(key))
      .forEach(([key, value]) => {
        commit('SET_FILTER', { name: key, value })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: false
}
