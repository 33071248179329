import Vue from 'vue'
import Vuex from 'vuex'

import * as utils from '@hypefactors/shared/js/utils'

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules
})

store.commit('STORE_AUTH_TOKEN', utils.load('access_token', ''))
// store.commit('SET_ACTIVE_BRAND_ID', utils.load('active_brand', null))

export default store
