import _pick from 'lodash/pick'

import { createGetter, createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import { MEDIA_TYPES_ARRAY } from '@hypefactors/shared/js/constants/mediaTypes'
import { SENTIMENT_TYPES_ARRAY } from '@hypefactors/shared/js/constants/sentimentTypes'
import { TIER_TYPES_ARRAY } from '@hypefactors/shared/js/constants/tierTypes'

const state = {
  bootstrapped: false,
  coverageQueryFilters: ['start', 'end', 'brands', 'tier', 'type', 'sentiment', 'tag', 'country'],
  markets: [],
  tags: []
}

const getters = {
  /**
   * Returns all the applied filters for the Measure page.
   *
   * @type {Object}
   */
  appliedCoverageFilters (state, getters, globalState) {
    return _pick(globalState.filters.filters, state.coverageQueryFilters)
  },

  /**
   * Returns the relevant markets for the report.
   *
   * @returns {Array}
   */
  markets: createGetter('markets'),

  /**
   * Returns the relevant tags for the report.
   *
   * @returns {Array}
   */
  tags: createGetter('tags'),

  hasMarkets (state, getters) {
    const appliedMarkets = getters.appliedCoverageFilters.country

    return appliedMarkets && appliedMarkets.length > 0
  },

  marketsToDisplay (state, getters) {
    const appliedMarkets = getters.appliedCoverageFilters.country

    if (appliedMarkets && appliedMarkets.length && getters.markets.length) {
      return getters.markets.filter(market => appliedMarkets.includes(market.iso_alpha2))
    }

    return [{ name: 'general.all' }]
  },

  tagsToDisplay (state, getters) {
    const appliedTags = getters.appliedCoverageFilters.tag

    if (appliedTags && appliedTags.length && getters.tags.length) {
      return getters.tags.filter(tag => appliedTags.includes(tag.title))
    }

    return [{ title: 'general.all' }]
  },

  sentimentToDisplay (state, getters) {
    const appliedSentiment = getters.appliedCoverageFilters.sentiment

    if (appliedSentiment && appliedSentiment.length) {
      return SENTIMENT_TYPES_ARRAY.filter(tag => appliedSentiment.includes(tag.key))
    }

    return [{ label: 'general.all' }]
  },

  mediaTypesToDisplay (state, getters) {
    const appliedMediaTypes = getters.appliedCoverageFilters.type

    if (appliedMediaTypes && appliedMediaTypes.length) {
      return MEDIA_TYPES_ARRAY.filter(type => appliedMediaTypes.includes(type.key))
    }

    return [{ label: 'general.all' }]
  },

  tiersToDisplay (state, getters) {
    const appliedTiers = getters.appliedCoverageFilters.tier

    if (appliedTiers && appliedTiers.length) {
      return TIER_TYPES_ARRAY.filter(tier => appliedTiers.includes(tier.key))
    }

    return [{ label: 'general.all' }]
  }
}

const mutations = {
  SET_BOOTSTRAPPED: createSetter('bootstrapped'),

  SET_MARKETS: createSetter('markets'),

  SET_TAGS: createSetter('tags')
}

const actions = {
  /**
   * Stores the provided filters object as filters.
   *
   * @param {Object} filtersObject
   *
   * @return {Promise}
   */
  syncCoverageFilters ({ dispatch, state }, filtersObject) {
    return dispatch('setFiltersFromQuery', {
      allowedQueryParams: state.coverageQueryFilters,
      query: filtersObject
    }, { root: true })
  }
}

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions
}
