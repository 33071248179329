import _merge from 'lodash/merge'

import { togglePlainElementFormArray } from '@hypefactors/shared/js/utils/arrayUtils'
import { createGetter, createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

import { Trans } from '@/services/TranslationService'

const state = {
  /**
   * The original Report object.
   *
   * @type {object}
   */
  report: null,

  /**
   * The list of available templates.
   *
   * @type {array}
   */
  templates: [],

  /**
   * The name of the report.
   *
   * @type {string}
   */
  name: null,

  /**
   * The UI settings.
   *
   * @type {object}
   */
  settings: {
    lang: null,
    timezone: null,
    reporterLogo: null,
    fontFamily: null,
    primaryColor: null, // TODO: This is the "theme color", rename this later
    primaryColorInvert: null // TODO: This is the "text color", rename this later
  },

  /**
   * List of pages that are hidden on the report..
   *
   * @type {string[]}
   */
  hiddenPages: []
}

const getters = {
  report: createGetter('report'),

  templates: createGetter('templates'),

  name: createGetter('name'),

  hiddenPages: createGetter('hiddenPages'),

  language: createGetter('settings.lang'),

  reporterLogo: createGetter('settings.reporterLogo'),

  fontFamily: createGetter('settings.fontFamily'),

  primaryColor: createGetter('settings.primaryColor'),

  primaryColorInvert: createGetter('settings.primaryColorInvert'),

  /**
   * Determines if the provided page is visible.
   *
   * @param {string} page
   *
   * @returns {boolean}
   */
  isPageVisible: state => (page) => {
    return !state.hiddenPages.includes(page)
  }
}

const actions = {
  setReport ({ commit }, report) {
    commit('SET_REPORT', report)
  },

  setTemplates ({ commit }, templates) {
    commit('SET_TEMPLATES', templates)
  },

  setName ({ commit }, name) {
    commit('SET_NAME', name)
  },

  setHiddenPages ({ commit }, hiddenPages) {
    commit('SET_HIDDEN_PAGES', hiddenPages)
  },

  async setLanguage ({ commit }, language) {
    const messages = await Trans.loadLanguageFile(language)

    Trans._setLocaleMessages(language, messages)

    commit('SET_LANGUAGE', language)
  },

  setReporterLogoUrl ({ commit }, logo) {
    commit('SET_REPORTER_LOGO', logo)
  },

  setFontFamily ({ commit }, fontFamily) {
    commit('SET_FONT_FAMILY', fontFamily)
  },

  setPrimaryColor ({ commit, dispatch }, primaryColor) {
    commit('SET_PRIMARY_COLOR', primaryColor)

    dispatch('setCustomCSSProperty', {
      property: '--user-theme__color-primary',
      value: primaryColor
    })
  },

  setPrimaryColorInvert ({ commit, dispatch }, primaryColorInvert) {
    commit('SET_PRIMARY_COLOR_INVERT', primaryColorInvert)

    dispatch('setCustomCSSProperty', {
      property: '--user-theme__color-primary-invert',
      value: primaryColorInvert
    })
  },

  setSettings ({ commit, dispatch }, settings) {
    commit('SET_SETTINGS', settings)

    dispatch('setLanguage', settings.lang)
    // dispatch('setReporterLogoUrl', settings.reporterLogo)
    // dispatch('setFontFamily', settings.fontFamily)
    dispatch('setPrimaryColor', settings.primaryColor)
    dispatch('setPrimaryColorInvert', settings.primaryColorInvert)
  },

  syncUiFromReport ({ dispatch }, report) {
    dispatch('syncUiFromData', {
      brandId: report.brand_id,
      name: report.name,
      filters: report.filters,
      settings: report.settings,
      hiddenPages: report.hidden_pages
    })
  },

  syncUiFromTemplate ({ state, dispatch }, template) {
    dispatch('syncUiFromData', {
      brandId: template.brand_id,
      name: null,
      filters: template.filters,
      settings: _merge(state.settings, template.settings, {}),
      hiddenPages: template.hidden_pages
    })
  },

  syncUiFromData ({ dispatch }, data = {}) {
    const settings = {
      lang: data.settings.lang || null,
      timezone: data.settings.timezone || null,
      reporterLogo: data.settings.reporterLogo || null,
      fontFamily: data.settings.fontFamily || null,
      primaryColor: data.settings.primaryColor || null,
      primaryColorInvert: data.settings.primaryColorInvert || null
    }

    dispatch('setActiveBrand', data.brandId, { root: true })

    dispatch('coverage/syncCoverageFilters', data.filters, { root: true })

    dispatch('setName', data.name)
    dispatch('setHiddenPages', data.hiddenPages || [])
    dispatch('setSettings', settings)
  },

  /**
   * Toggles the hidden state of a single component.
   *
   * @param {string} element
   */
  toggleSinglePage ({ state, commit }, element) {
    const hiddenPages = togglePlainElementFormArray(state.hiddenPages, element)

    commit('SET_HIDDEN_PAGES', hiddenPages)
  },

  setCustomCSSProperty ({ state }, { property, value }) {
    document.documentElement.style.setProperty(property, value)
  }
}

const mutations = {
  SET_REPORT: createSetter('report'),

  SET_TEMPLATES: createSetter('templates'),

  SET_NAME: createSetter('name'),

  SET_LANGUAGE: createSetter('settings.lang', ''),

  SET_HIDDEN_PAGES: createSetter('hiddenPages', []),

  SET_SETTINGS: createSetter('settings', {}),

  SET_REPORTER_LOGO: createSetter('settings.reporterLogo', ''),

  SET_FONT_FAMILY: createSetter('settings.fontFamily', ''),

  SET_PRIMARY_COLOR: createSetter('settings.primaryColor', ''),

  SET_PRIMARY_COLOR_INVERT: createSetter('settings.primaryColorInvert', '')
}

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
}
