import Chart from '@hypefactors/shared/js/components/charts/base/Chart'
import * as moneyFormatters from '@hypefactors/shared/js/utils/moneyFormatters'
import * as routerUtilities from '@hypefactors/shared/js/utils/routerUtilities'
import * as ApiInstanceInjector from '@hypefactors/shared/js/services/api/ApiInstanceInjector'

import store from '@/store'
import api from '@/services/Api'
import currencyConverterInstance from '@/services/CurrencyConverter'

import { Trans } from '@/services/TranslationService'
import { currencySymbol } from '@/utils/currencySymbol'

// passes the api instance on the MetricsService shared Api service
ApiInstanceInjector.setApi(api)

/**
 * passes the {@see CurrencyConverter#convertCurrency} and the {@see module:currencySymbol} helper
 */
moneyFormatters.setMoneyHelpers(currencyConverterInstance.convertCurrency.bind(currencyConverterInstance), currencySymbol)

/* Override the defaultNoDataMessage method on the Chart class'es prototype */
Chart.prototype.defaultNoDataMessage = () => Trans.i18n.t('components.charts.no_data_available')

// set the store on the router utils
routerUtilities.setConfigurations({
  isLoggedIn: () => store.getters.isLoggedIn,
  homeRouteName: 'home'
})
