export function brandFactory () {
  return {
    id: 0,
    slug: 'n-a',
    name: 'N/A',
    currency_code: 'N/A',
    members: {
      data: []
    }
  }
}
