import { createGetter, createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

import Api from '@/services/Api'
import currencyConverter from '@/services/CurrencyConverter'

const state = {
  currencies: [],
  languages: []
}

const getters = {
  currencies: createGetter('currencies'),

  languages: createGetter('languages'),

  // TODO: Move out of the store.. this doesn't really belong here...
  // Currently being used on shared components, which is totally wrong...
  convertCurrency: (state) => (amount, from, to) => {
    return currencyConverter.convertCurrency(amount, from, to)
  }
}

const mutations = {
  SET_CURRENCIES: createSetter('currencies'),

  SET_LANGUAGES: createSetter('languages')
}

const actions = {
  // TODO: Keeping it here for now since there are other places relying on this
  async fetchCurrencies ({ commit, state }) {
    if (state.currencies.length) {
      return
    }
    const currencies = await Api.getData('currencies')

    currencyConverter.updateCurrencies(currencies)

    commit('SET_CURRENCIES', currencies)
  },

  // TODO: Keeping it here for now since there are other places relying on this
  async fetchLanguages ({ commit, state }) {
    if (state.languages.length) {
      return
    }

    const response = await Api.getData('languages')

    commit('SET_LANGUAGES', response)
  },

  setCurrencies ({ commit }, currencies) {
    commit('SET_CURRENCIES', currencies)
  },

  setLanguages ({ commit }, languages) {
    commit('SET_LANGUAGES', languages)
  }
}

// TODO: Namespace this later...
export default {
  state,
  getters,
  mutations,
  actions
}
