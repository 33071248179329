import store from '@/store'

/**
 * Sets the auth tokens intelligently
 *
 * @param {Route} current
 * @param {Route} previous
 * @param {Function} next
 */
export function EnsureAuthTokenIsSet (current, previous, next) {
  store.dispatch('setAuthTokenIntelligently', { query: current.query }).then(() => {
    next()
  })
}
