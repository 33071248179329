import Vue from 'vue'

import ColorPicker from 'element-ui/lib/color-picker'
import DatePicker from 'element-ui/lib/date-picker'
import Dialog from 'element-ui/lib/dialog'
import ElementLocale from 'element-ui/lib/locale/index'
import Loading from 'element-ui/lib/loading'
import MessageBox from 'element-ui/lib/message-box'
import Notification from 'element-ui/lib/notification'
import Option from 'element-ui/lib/option'
import Popover from 'element-ui/lib/popover'
import Select from 'element-ui/lib/select'

import { i18n } from './i18n'

Vue.use(ColorPicker)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Loading.directive)
Vue.use(Option)
Vue.use(Popover)
Vue.use(Select)

Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification

ElementLocale.i18n((key, value) => i18n.t(key, value))
