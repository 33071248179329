import {
  loadPageAsync as load,
  disallowIfLoggedIn,
  disallowIfNotLoggedIn
} from '@hypefactors/shared/js/utils/routerUtilities'

import { EnsureAuthTokenIsSet } from '@/router/Middlewares'

export default [
  {
    path: '',
    beforeEnter: EnsureAuthTokenIsSet,
    component: load('Bootstrapper'),
    children: [
      {
        path: '/auth',
        component: load('auth/Auth', { domain: 'shared' }),
        meta: { pageTitle: 'Auth | Hypefactors Report' },
        children: [
          {
            path: 'logout',
            name: 'logout',
            beforeEnter: disallowIfNotLoggedIn,
            component: load('auth/Logout', { domain: 'shared' })
          },
          {
            name: 'login',
            path: 'login',
            beforeEnter: disallowIfLoggedIn,
            component: load('auth/Login')
          }
        ]
      },
      {
        path: '/',
        beforeEnter: disallowIfNotLoggedIn,
        component: load('App'),
        children: [
          {
            path: '',
            name: 'home',
            component: load('Home')
          },
          {
            path: 'measure',
            component: load('measure/_'),
            children: [
              {
                path: ':reportId',
                name: 'measure.manage',
                component: load('measure/Manage')
              },
              {
                path: ':reportId/view',
                name: 'measure.view',
                component: load('measure/View')
              }
            ]
          }
        ]
      },
      {
        path: '*',
        beforeEnter: disallowIfNotLoggedIn,
        component: load('Home')
      }
    ]
  }
]
