import Store from '@/store/index'
import _get from 'lodash/get'

/**
 * @module currencySymbol
 * @return {string} the currently active currency
 */
export const currencySymbol = () => {
  return _get(Store, 'getters.activeBrand.currency_code', 'EUR')
}
