<script>
export default {
  name: 'Root',

  async created () {
    if (this.$isDevMode) {
      // make page scrollable for debugging
      window.document.querySelector('html').style.overflowX = 'auto'
    }
  },

  render: h => h('router-view')
}
</script>

<style lang="scss">
@import '@/assets/styles/app.scss';
</style>
