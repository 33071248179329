import Vue from 'vue'
import Vuelidate from 'vuelidate'
import YouTubeEmbed from 'vue-youtube-embed'
import VueMultianalytics from 'vue-multianalytics'
import Clickoutside from 'element-ui/lib/utils/clickoutside'
import VuelidateErrorExtractor from 'vuelidate-error-extractor'

import { sync } from 'vuex-router-sync'
import { directive as vueClickAway } from 'vue-clickaway'

import { brandIdMixin } from '@hypefactors/shared/js/mixins/brandIdMixin'
import { VueEcho } from '@hypefactors/shared/js/plugins/echo'
import { EchoInstance } from '@hypefactors/shared/js/services/SocketService'

import { authorizeMixin } from '@/bootstrap/authorizeMixin'

import store from '@/store'
import router from '@/router'

sync(store, router)

Vue.mixin(authorizeMixin)
Vue.mixin(brandIdMixin)

Vue.use(YouTubeEmbed)
Vue.use(Vuelidate)
Vue.use(VueEcho, EchoInstance)

Vue.directive('clickAway', vueClickAway)
Vue.directive('clickoutside', Clickoutside)

Vue.use(VuelidateErrorExtractor, {
  i18n: 'validations'
})

/* Enable analytics only for none CI env */
if (!['ci', 'development'].includes(process.env.VUE_APP_ENV)) {
  const gaConfig = {
    appName: 'HypeReport', // Mandatory
    appVersion: '1', // Mandatory
    trackingId: 'UA-68682856-4' // Mandatory
  }

  const facebookConfig = {
    token: '1723714561184387'
  }

  Vue.use(VueMultianalytics, {
    modules: {
      ga: gaConfig,
      facebook: facebookConfig,
      segment: {
        token: process.env.VUE_APP_SEGMENT_KEY,
        debug: process.env.VUE_APP_ENV !== 'production'
      }
    },
    routing: {
      vueRouter: router
    }
  })
} else {
  Vue.prototype.$ma = {
    trackEvent: () => {},
    setUsername: () => {},
    reset: () => {}
  }
}
