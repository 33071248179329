import filters from '@hypefactors/shared/js/vuex/modules/filters'
import globalFilters from '@hypefactors/shared/js/vuex/modules/globalFilters'

import app from './app'
import auth from './auth'
import coverage from './coverage'
import measure from './measure'

export default {
  filters,
  globalFilters,

  app,
  auth,
  coverage,
  measure
}
