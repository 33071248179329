import 'intersection-observer'

import Vue from 'vue'

import store from '@/store'
import router from '@/router'
import { i18n } from '@/bootstrap/i18n'

import Root from '@/Root.vue'

import '@/bootstrap/setupSharedDependencies'
import '@/bootstrap/prototypeProperties'
import '@/bootstrap/InitElementUi'
import '@/bootstrap/plugins'

Vue.config.productionTip = false

const app = new Vue({
  el: '#hypefactors-report',
  router,
  store,
  i18n,
  render: h => h(Root)
})

if (window.Cypress) {
  window.app = app
}
