import { load, localToUtcTimestamp } from '@hypefactors/shared/js/utils'

import router from '@/router'

export function attachAccessToken (config) {
  const accessToken = load('access_token', '')

  config.headers.common['Authorization'] = `Bearer ${accessToken}`

  return config
}

export function unauthorizedInterceptor (err) {
  // temporary solution until refresh tokens are available
  if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    const currentLoc = window.location.pathname + window.location.search
    // redirect only if the current route is not logout or login
    if (currentLoc.match(/logout|login/) === null) {
      router.replace({
        name: 'logout',
        query: {
          redirect: currentLoc,
          reason: '401'
        }
      })
      return Promise.reject(err)
    }

    return Promise.reject(err)
  }

  return Promise.reject(err)
}

export function metricsDates (config) {
  // check if we have a start or end date
  if (config.params && config.params.start && config.params.end) {
    config.params = {
      ...config.params,
      start: localToUtcTimestamp(config.params.start),
      end: localToUtcTimestamp(config.params.end)
    }
  }
  return config
}
